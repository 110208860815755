import { AssetDimensions } from "@/store/types";

export function getVideoDimensions(file: Blob): Promise<AssetDimensions> {
    return new Promise((resolve) => {
        const _URL = window.URL || window.webkitURL;

        const img = document.createElement("video")

        img.addEventListener('loadedmetadata', function () {
            resolve({ width: this.videoWidth, height: this.videoHeight })
        })

        img.src = _URL.createObjectURL(file)
    })
}
