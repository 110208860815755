import { AssetDimensions } from "@/store/types";

export function getImageDimensions(file: Blob): Promise<AssetDimensions> {
    return new Promise((resolve) => {
        const _URL = window.URL || window.webkitURL;

        const img = new Image()

        img.onload = function() {
            const { width, height } = this as HTMLImageElement
            resolve({ width, height })
        };

        img.src = _URL.createObjectURL(file)
    })
}
