




















import Vue from "vue";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import BannerEditor from "@/views/Banners/components/BannerEditor.vue";
import {Image} from "@/store/types";

export default Vue.extend({
  name: "FeedBannersTable",

  props: {
    items: { type: Array, default: null },
    loading: { type: Boolean, default: false },
    total: { type: Number, default: 0 }
  },

  data: () => ({
    fullScreenDialog: false,
    options: {},
    page: 1,
    pageSize: 10,
    headers: [
      {
        text: "Nome",
        value: "name",
        sortable: true,
      },
      {
        text: "Status",
        align: "center",
        value: "sharing_status",
        sortable: false,
      },
      {
        text: "Revisor",
        align: "center",
        value: "reviewed_by",
        sortable: false,
      },
      {
        text: "Tags",
        align: "center",
        value: "tags",
        sortable: false,
      },
      {
        text: "Ações",
        value: "actions",
        width: "110",
        align: "center",
        sortable: false
      }
    ] as Array<Object>
  }),

  watch: {
    options: {
      handler (options) {
        this.fetch(options.page, options.itemsPerPage);
      },
      deep: true,
    },
  },

  methods: {
    fetch(page: number, pageSize: number): void {
      if (page === this.page && pageSize === this.pageSize) return;
      this.page = page;
      this.pageSize = pageSize;

      // FIXME o backend começa a paginação em 0, resolver isso depois
      this.$emit("fetch", { page: page - 1, pageSize })
    },

    onUpdate(item: Image): void {
      //this.$emit("onUpdate", item)
    },

    onSelectItem(item: Image): void {
      this.$store.commit('feedBannersModule/setBanner', item)
      this.fullScreenDialog = true
    },
  }
})
