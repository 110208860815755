





























import Vue from "vue";
import { mapGetters } from "vuex";
import CollTable from "./components/FeedBannersTable.vue";
import BannersFilters from "./components/FeedBannersFilters.vue";

import { Banner } from "@/store/types";
import FeedBannerViewer from "./components/FeedBannerViewer.vue";

export default Vue.extend({
  name: "FeedBannersPage",

  components: {
    CollTable,
    BannersFilters,
    FeedBannerViewer,
  },

  data: () => ({
    fullScreenDialog: false,
    banner: null as unknown | Banner,
  }),

  computed: {
    ...mapGetters({
      banners: "feedBannersModule/getBanners",
      total: "feedBannersModule/getTotal",
      loading: "feedBannersModule/getLoading"
    })
  },

  created() {
    this.$store.dispatch("feedBannersModule/fetch");
  },

  methods: {
    editCol(banner: Banner): void {
      this.banner = banner;
      this.openFullScreenDialog();
    },

    onUpdate(banner: Banner): void {
      this.$store.dispatch("feedBannersModule/update", banner);
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    fetch(payload: { page: number, pageSize: number }): void {
      this.$store.dispatch("feedBannersModule/fetch", payload);
    },

    closeFullScreenDialog(): void {
      this.fullScreenDialog = false;
      this.banner = null;
    },
  }
})
