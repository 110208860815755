































import Vue from "vue";
import { SharingStatus } from "@/store/types";

export default Vue.extend({
  name: "ReasonForm",

  props: {
    status: { type: String, default: SharingStatus.REJECTED },
    id: { type: String, required: true }
  },

  data() {
    return {
      reason: "",
      sharingStatus: SharingStatus,
      requireReasonStatus: [SharingStatus.BANNED, SharingStatus.REJECTED],
      rejectOptions: [
        { text: "Duplicação", value: "Este banner é uma duplicata exata de outro já existente em nosso sistema. Encorajamos a criação de conteúdo original e diversificado." },
        { text: "Informações Insuficientes", value: "Seu banner foi rejeitado por não incluir todas as informações necessárias: imagem do produto, título e preço." },
        { text: "Qualidade Visual Insuficiente", value: "A qualidade visual do seu banner não atende aos nossos padrões. Por favor, assegure-se de que as imagens e textos são claros e legíveis." },
        { text: "Informações Imprecisas", value: "Encontramos discrepâncias nas informações do produto apresentadas no banner. Por favor, verifique as informações e tente novamente." },
        { text: "Conteúdo Inadequado", value: "Seu banner contém conteúdo que viola nossas diretrizes. Conteúdo ofensivo, discriminatório ou que promove atividades ilegais não é permitido." },
      ],
      banOptions: [
        { text: "Violação Grave de Conteúdo", value: "Banners que contêm conteúdo explicitamente ofensivo, discriminatório, ou que promovem atividades ilegais serão imediatamente banidos. Este tipo de conteúdo não apenas viola nossas diretrizes, mas também prejudica a integridade e a segurança da comunidade." },
        { text: "Falsificação e Engano", value: "Banners que apresentam informações deliberadamente falsas ou enganosas sobre o produto, incluindo preços manipulados ou características inexistentes, serão banidos. A honestidade é crucial para manter a confiança dentro da plataforma." },
        { text: "Reincidência em Duplicação", value: "Banners que repetidamente copiam outros conteúdos já existentes no sistema, mesmo após rejeições anteriores por duplicação, resultarão em banimento. Buscamos incentivar a originalidade e a inovação entre nossos usuários." },
        { text: "Uso Não Autorizado de Conteúdo Protegido", value: "O uso de imagens, textos ou outros materiais protegidos por direitos autorais sem permissão resultará no banimento do banner. Respeitar os direitos de propriedade intelectual é fundamental." },
        { text: "Problemas Recorrentes de Qualidade Visual", value: "Banners que constantemente falham em atender aos padrões de qualidade visual, como imagens pixelizadas ou textos ilegíveis, e que não são corrigidos após feedbacks de rejeição, podem ser banidos para garantir a qualidade do conteúdo no feed." },
        { text: "Manipulação de Funcionalidades", value: "Banners projetados para manipular algoritmos ou para obter vantagens injustas (como spamming ou fishing) serão banidos. Tais práticas comprometem a funcionalidade e a equidade da plataforma." },
      ],
      isLoading: false,
    };
  },

  methods: {
    save(): void {
      if (!this.validateForm()) return;

      if(this.status === SharingStatus.APPROVED) {
        return this.onApproveBanner();
      }

      if(this.status === SharingStatus.REJECTED) {
        return this.onRejectBanner();
      }

      if(this.status === SharingStatus.BANNED) {
        return this.onBanBanner();
      }
    },

    validateForm(): Boolean {
      if (this.requireReasonStatus.includes(this.status as SharingStatus) && !this.reason) {
        this.showError("Escolha um motivo!");
        return false;
      }
      return true;
    },

    onApproveBanner(): void {
      this.isLoading = true;
      this.$store.dispatch("feedBannersModule/update", {
        id: this.id,
        sharing_status: SharingStatus.APPROVED,
        banned: false,
        reason_for_ban: "",
        reason_for_rejection: "",
        reviewed_by: "admin",
      })
          .then(this.close)
          .finally(() => this.isLoading = false);
    },

    onRejectBanner(): void {
      this.isLoading = true;
      this.$store.dispatch("feedBannersModule/update", {
        id: this.id,
        sharing_status: SharingStatus.REJECTED,
        banned: false,
        reason_for_ban: "",
        reason_for_rejection: this.reason,
        reviewed_by: "admin",
      })
          .then(this.close)
          .finally(() => this.isLoading = false);
    },

    onBanBanner(): void {
      this.isLoading = true;
      this.$store.dispatch("feedBannersModule/update", {
        id: this.id,
        sharing_status: SharingStatus.BANNED,
        banned: true,
        reason_for_ban: this.reason,
        reason_for_rejection: "",
        reviewed_by: "admin",
      })
          .then(this.close)
          .finally(() => this.isLoading = false);
    },

    showError(message: string): void {
      this.$store.dispatch("app/showCustomError", message);
    },

    close() {
      this.$emit('close')
    }
  }
});
