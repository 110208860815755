export function getHexColor(color: number) {
  if (!color) return `rgba(142,114,255,1)`;

  const alpha = (color & 0xFF000000) >>> 24;
  const red = (color & 0x00FF0000) >>> 16;
  const green = (color & 0x0000FF00) >>> 8;
  const blue = color & 0x000000FF;

  return `rgba(${red},${green},${blue},${alpha/255})`;
}

export function rgbaTo32Bit(r: number, g: number, b: number, a: number) {
  const alpha = Math.round(a * 255);

  return ((alpha << 24) >>> 0) + (r << 16) + (g << 8) + b;
}
