














































































































































































































import Vue from "vue";
import { Banner, Item, ItemType, SharingStatus } from "@/store/types";
import { mapGetters } from "vuex";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import ReasonForm from "./ReasonForm.vue"
import { getCssValue, getHexColor } from "@/utils"

export default Vue.extend({
  name: "FeedBannerViewer",

  components: {
    FullScreenDialog,
    ReasonForm
  },

  data: () => ({
    scale: 4,
    imageTypes: [ItemType.BG_IMAGE, ItemType.IMAGE],
    itemType: ItemType,
    color: {
      r: 1,
      g: 2,
      b: 1,
      a: 2
    },
    select: [
      { text: "Sim", value: true },
      { text: "Não", value: false }
    ],
    textReferences: [
      { text: "Nenhum", value: '' },
      { text: "Nome da Oferta", value: 'offer.name' },
      { text: "Preço Atual", value: 'offer.price' },
      { text: "Preço Anterior", value: 'offer.price_from' },
      { text: "Parcelamento", value: 'offer.installment' },
      { text: "SKU", value: 'offer.sku' },
      { text: "Nome do Vendedor", value: 'seller.name' },
      { text: "WhatsApp", value: 'seller.whatsapp' },
      { text: "Instagram", value: 'seller.instagram' },
      { text: "Facebook", value: 'seller.facebook' },
    ],
    imageReferences: [
      { text: "Nenhum", value: '' },
      { text: "Foto da Oferta", value: 'offer.thumbnail' },
    ],
    isLoading: false,
    status: undefined as SharingStatus | undefined,
    sharingStatus: SharingStatus,
  }),

  computed: {
    ...mapGetters({
      banner: "feedBannersModule/getBanner",
    }),
    getScale(): number {
      return this.scale / 10;
    },
    payload(): Banner {
      return JSON.parse(this.banner.template) as Banner
    }
  },

  methods: {
    openDialogConfirmation(status: SharingStatus): void {
      this.status = status;
    },

    getAlignment(key = 'TextAlign.left'): string {
      const values = {
        'TextAlign.left': 'left',
        'TextAlign.center': 'center',
        'TextAlign.right': 'right',
        'TextAlign.justify': 'justify'
      }

      return getCssValue(values, key, 'left')
    },

    getWeight(key = 'FontWeight.w400'): string {
      const values = {
        'FontWeight.w400': 'normal',
        'FontWeight.w700': 'bold',
      }

      return getCssValue(values, key, 'normal')
    },

    getDecoration(key = 'TextDecoration.none'): string {
      const values = {
        'TextDecoration.none': 'none',
        'TextDecoration.lineThrough': 'line-through',
        'TextDecoration.overline': 'overline',
        'TextDecoration.underline': 'underline'
      }

      return getCssValue(values, key, 'none')
    },

    getStyle(key = 'FontStyle.normal'): string {
      const values = {
        'FontStyle.normal': 'none',
        'FontStyle.italic': 'italic',
      }

      return getCssValue(values, key, 'none')
    },

    getFontFamily(key = 'Avenir'): string {
      const values = {
        'Avenir': 'Avenir',
        'Berkshire Swash': 'Berkshire Swash',
        'Rubik Dirt': 'Rubik Dirt',
        'OpenSans': 'Open Sans',
        'Pacifico': 'Pacifico',
        'Paytone One': 'Paytone One',
        'Permanent Marker': 'Permanent Marker',
        'Righteous': 'Righteous',
        'Roboto': 'Roboto',
        'Roboto Slab': 'Roboto Slab',
        'Rubik Marker Hatch': 'Rubik Marker Hatch',
        'Titan One': 'Titan One',
        'Ubuntu': 'Ubuntu',
        'Ultra Regular': 'Ultra',
      }

      return getCssValue(values, key, 'Avenir')
    },

    getHexColor(color: number): string {
      return getHexColor(color);
    },
  }
})
