<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container style="margin: 50px 0 0; padding: 0 10px">
      <v-row>
        <v-col cols="12" md="5">
          <v-select
            v-model="sharing_status"
            :items="sharingOptions"
            item-text="text"
            item-value="value"
            label="Status"
          />
        </v-col>

        <v-col cols="12" md="4" style="margin-top: 20px">
          <v-btn
            small
            color="error"
            class="mr-4"
            @click="reset"
          >
            Limpar
          </v-btn>

          <v-btn
            small
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="handleFilter"
          >
            Buscar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script lang="js">

import { SharingStatus } from "@/store/types";

export default {
  name: "FeedBannersFilters",

  data: () => ({
    valid: true,
    sharing_status: SharingStatus.PENDING_REVIEW,
    options: [
      { text: "Sim", value: true },
      { text: "Não", value: false }
    ],
    sharingOptions: [
      { text: "Desativado", value: SharingStatus.UNSET },
      { text: "Pendente de Revisão", value: SharingStatus.PENDING_REVIEW },
      { text: "Revisando", value: SharingStatus.REVIEWING },
      { text: "Rejeitado", value: SharingStatus.REJECTED },
      { text: "Suspenso", value: SharingStatus.SUSPENDED },
      { text: "Banido", value: SharingStatus.BANNED },
      { text: "Aprovado", value: SharingStatus.APPROVED },
    ]
  }),

  methods: {
    handleFilter () {
      const { shared, sharing_status } = this
      this.$store.commit("feedBannersModule/setFilters", { shared, sharing_status })
      this.$store.dispatch("feedBannersModule/fetch")
    },

    reset () {
      this.$refs.form.reset()
      this.$store.commit("feedBannersModule/setFilters", { sharing_status: SharingStatus.PENDING_REVIEW })
      this.$store.dispatch("feedBannersModule/fetch")
    }
  },
}
</script>

<style scoped>

</style>
